export default {
  component: () => import('./page.vue'),
  path: '/hotel/faq',
  name: 'hotel-faq',
  meta: {
    title: '调整坐姿',
    auth: false,
    needPhone: false,
    wechat: {
      TimelineShare: false,
      baseAuth: false,
    },
  },
};
