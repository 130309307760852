import { createCache, PetShop } from 'pet-shop';

const namespace = `xinxiangsui-${import.meta.env.CONFIG_NAME}`;

export const localStore = PetShop({
  namespace,
  storage: localStorage,
  json: true,
});

export const sessionStore = PetShop({
  namespace,
  storage: sessionStorage,
  json: true,
});

export const Auth = createCache(sessionStore, [
  'token',
  'tenant',
  'code',
  'info',
]);

export const Experiments = createCache(localStore, ['useMiniApp']);

export const Temp = createCache(sessionStore, ['loop', 'deviceId']);

if (import.meta.env.WATCHING) {
  Auth.token = '43353e2c-ff1a-11ef-a3e3-55abb254a040';
}
